import React from 'react';
import { Formik, Form, Field as FormikField } from 'formik';
import * as yup from 'yup';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

const schema = yup.object().shape({
  name: yup.string().max(64).required(),
  email: yup.string().email().max(64).required(),
  message: yup.string().max(5000).required(),
});

const Field = ({ label, id, className = '', ...passThroughProps }) => {
  return (
    <div className={`relative inline-block ${className}`}>
      <label
        htmlFor={id}
        className="absolute top-0 left-0 right-0 px-4 pt-2 text-sm font-bold uppercase font-display"
      >
        {label}
      </label>
      <FormikField
        className="w-full min-h-full pb-1 pl-4 rounded-lg outline-none pt-7"
        id={id}
        {...passThroughProps}
      />
    </div>
  );
};

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query ContactFormQuery {
      site {
        siteMetadata {
          submitTicketUrl
        }
      }
    }
  `);

  const handleSubmit = async (
    { name, email, message },
    { setStatus, resetForm }
  ) => {
    try {
      const res = await fetch(data.site.siteMetadata.submitTicketUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      }

      resetForm();
      setStatus(0);
    } catch (error) {
      setStatus(1);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
      }}
      initialStatus={null}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <div
            hidden={status === null}
            className={classNames('p-4 mb-4 rounded-lg lg:mb-6', {
              'bg-green text-green-dark': status === 0,
              'bg-red text-red-dark': status === 1,
            })}
          >
            {status === 0
              ? 'Your ticket was submitted.'
              : 'Error occurred. Please send e-mail instead.'}
          </div>
          <div className="flex flex-col lg:flex-row">
            <Field
              className="flex-grow"
              name="name"
              id="name"
              label="Name"
              maxLength="64"
              required
            />
            <Field
              className="flex-grow mt-4 lg:mt-0 lg:ml-6"
              name="email"
              id="email"
              label="E-mail"
              type="email"
              maxLength="64"
              required
            />
          </div>
          <Field
            className="w-full h-40 my-4 lg:my-6"
            as="textarea"
            label="Message"
            id="message"
            name="message"
            maxLength="5000"
            required
          />
          <div className="text-center lg:text-right">
            <button
              type="submit"
              className="btn-yellow"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
