import React from 'react';
import { graphql } from 'gatsby';
import ClipboardJS from 'clipboard';

import SEO from '../../components/SEO';
import HelpLayout from '../../components/HelpLayout';
import Card from '../../components/Card';
import ContactForm from '../../components/ContactForm';

const ContactPage = ({ location, data }) => {
  const [isSupportEmailCopied, setIsSupportEmailCopied] = React.useState(false);

  React.useEffect(() => {
    const clipboard = new ClipboardJS('#copy-support-email');
    clipboard.on('success', () => {
      setIsSupportEmailCopied(true);
    });

    return () => {
      clipboard.destroy();
    };
  }, []);

  return (
    <HelpLayout location={location}>
      <SEO title="Contact" description="Get in touch with us." />
      <Card>
        <Card.Heading>E-mail us directly</Card.Heading>
        <Card.Body>
          <div className="flex flex-col items-center lg:flex-row">
            <a
              href={`mailto:${data.site.siteMetadata.supportEmail}`}
              className="relative flex-grow w-full p-4 text-center bg-white rounded-lg"
            >
              <span id="support-email">
                {data.site.siteMetadata.supportEmail}
              </span>
              <span hidden={!isSupportEmailCopied} className="absolute ml-4">
                ✅
              </span>
            </a>
            <button
              type="button"
              id="copy-support-email"
              data-clipboard-target="#support-email"
              className="flex-shrink-0 mt-4 lg:mt-0 lg:ml-6 btn-yellow"
            >
              Copy address
            </button>
          </div>
        </Card.Body>
      </Card>
      <Card className="mt-8">
        <Card.Heading>Submit a ticket</Card.Heading>
        <Card.Body>
          <ContactForm />
        </Card.Body>
      </Card>
    </HelpLayout>
  );
};

export const query = graphql`
  query HelpContactQuery {
    site {
      siteMetadata {
        supportEmail
      }
    }
  }
`;

export default ContactPage;
